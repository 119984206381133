/* global contract_type:writable, moment */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import ReservationApi from "../api/ReservationApi";
import { isTypeReservation } from "../ReservationType";
import { rearangeArticlesData } from "./rearangeArticlesData";

const initialState = {
    status: 'idle',
    error: null,

    notification: {
        code: "",
        show: false,
        action_after_notification: null,
    },

    reservation_id: 0,
    reservation_data: {},

    not_available_articles: [],
};

export const convertDateForServer = (uiDate) => {
    const date = moment(uiDate, "DD.MM.YYYY HH:mm")
    return date.format("YYYY-MM-DDTHH:mm");
}


export const getReservation = createAsyncThunk(
    'reservation/getDetails',
    async (_noParams, { getState }) => {
        const reservation_id = getState().reservation.reservation_id;

        const response = await ReservationApi.getData(reservation_id);
        return response.reservation_data;
    }
)

export const addNewArticle = createAsyncThunk(
    'reservation/addNewArticle',
    async ({article_id, from, to, subarticles_already_in_contract}, { getState }) => {
        const state = getState();
        const reservation_id = selectReservationId(state);

        const response = await ReservationApi.addNewArticle(reservation_id, article_id, from, to, subarticles_already_in_contract);
        return response;
    }
)

export const setNewCustomer = createAsyncThunk(
    'reservation/setNewCustomer',
    async (customer_id, { getState }) => {
        const state = getState();
        const reservation_id = selectReservationId(state);

        const response = await ReservationApi.setNewCustomer(reservation_id, customer_id);
        return response;
    }
)

export const setArticleDates = createAsyncThunk(
    'reservation/setArticleDates',
    async ({article_id, subarticle_id, from, to, recurring_id, recurrence_pos}, { getState }) => {
        const state = getState();
        const reservation_id = selectReservationId(state);

        const response = await ReservationApi.setArticleDates(reservation_id, article_id, subarticle_id, from, to, recurring_id, recurrence_pos);
        return response
    }
)

export const setArticleCount = createAsyncThunk(
    'reservation/setArticleCount',
    async ({article_id, new_count, recurring_id, recurrence_pos}, { getState }) => {
        const state = getState();
        const reservation_id = selectReservationId(state);

        const response = await ReservationApi.setArticleCount(reservation_id, article_id, new_count, recurring_id, recurrence_pos);
        return response
    }
)

export const removeArticleFromContract = createAsyncThunk(
    'reservation/removeArticleFromContract',
    async ({article_id, subarticle_id, recurring_id, recurring_pos}, { getState }) => {
        const state = getState();
        const reservation_id = selectReservationId(state);

        const response = await ReservationApi.removeArticleFromContract(reservation_id, article_id, subarticle_id, recurring_id, recurring_pos);
        return response
    }
)

export const replaceSubarticleInContract = createAsyncThunk(
    'reservation/replaceSubarticleInContract',
    async ({article_id, remove_subarticle_id, recurring_id, recurring_pos, new_subarticle_id, from, to}, { getState }) => {
        const state = getState();
        const reservation_id = selectReservationId(state);

        const response = await ReservationApi.replaceSubarticleInContract(reservation_id, article_id, remove_subarticle_id, recurring_id, recurring_pos, 
            new_subarticle_id, from, to);

        return response
    }
)

export const anonymize = createAsyncThunk(
    'reservation/anonymize',
    async (_params, { getState }) => {
        const state = getState();
        const reservation_id = selectReservationId(state);

        const response = await ReservationApi.anonymize(reservation_id);
        return response
    }
)

export const convertRequestToReservation = createAsyncThunk(
    'reservation/convertRequestToReservation',
    async (_params, { getState }) => {
        const state = getState();
        const reservation_id = selectReservationId(state);

        const response = await ReservationApi.convertRequestToReservation(reservation_id);
        return response
    }
)

export const changeReservationStatus = createAsyncThunk(
    'reservation/changeReservationStatus',
    async ({ status }, { getState }) => {
        const state = getState();
        const reservation_id = selectReservationId(state);

        const response = await ReservationApi.changeReservationStatus(reservation_id, status);
        return response
    }
)

export const rejectRequest = createAsyncThunk(
    'reservation/rejectRequest',
    async ({ status }, { getState }) => {
        const state = getState();
        const reservation_id = selectReservationId(state);

        const response = await ReservationApi.rejectRequest(reservation_id, status);
        return response
    }
)

export const addNewHandout = createAsyncThunk(
    'reservation/addNewHandout',
    async ({article_id, subarticle_id, count, day, recurring_id, recurrence_pos}, { getState }) => {
        const state = getState();
        const reservation_id = selectReservationId(state);

        const response = await ReservationApi.addNewHandout(reservation_id, article_id, subarticle_id, count, day, recurring_id, recurrence_pos);
        return response
    }
)

export const changeHandout = createAsyncThunk(
    'reservation/changeHandout',
    async ({handout_id, article_id, subarticle_id, count, day, recurring_id, recurrence_pos}, { getState }) => {
        const state = getState();
        const reservation_id = selectReservationId(state);

        const response = await ReservationApi.changeHandout(reservation_id, handout_id, article_id, subarticle_id, count, day, recurring_id, recurrence_pos);
        return response
    }
)

export const addNewReturn = createAsyncThunk(
    'reservation/addNewReturn',
    async ({article_id, subarticle_id, count, day, recurring_id, recurrence_pos}, { getState }) => {
        const state = getState();
        const reservation_id = selectReservationId(state);

        const response = await ReservationApi.addNewReturn(reservation_id, article_id, subarticle_id, count, day, recurring_id, recurrence_pos);
        return response
    }
)

export const changeReturn = createAsyncThunk(
    'reservation/changeReturn',
    async ({return_id, article_id, subarticle_id, count, day, recurring_id, recurrence_pos}, { getState }) => {
        const state = getState();
        const reservation_id = selectReservationId(state);

        const response = await ReservationApi.changeReturn(reservation_id, return_id, article_id, subarticle_id, count, day, recurring_id, recurrence_pos);
        return response
    }
)

export const handoutAll = createAsyncThunk(
    'reservation/handoutAll',
    async (date, { getState }) => {
        const state = getState();
        const reservation_id = selectReservationId(state);

        const response = await ReservationApi.handoutAll(reservation_id, date);
        return response;
    }
)

export const returnAll = createAsyncThunk(
    'reservation/returnAll',
    async (date, { getState }) => {
        const state = getState();
        const reservation_id = selectReservationId(state);

        const response = await ReservationApi.returnAll(reservation_id, date);
        return response;
    }
)

// sets articles and history from response
const setStateArticleDataFromResponse = (response, state) => {
    if(response.success === true) {
        const reservation_articles = response.reservation_articles;

        const articles_data = rearangeArticlesData(reservation_articles, state);
        state.reservation_data.articles = articles_data.articles;
        state.reservation_data.history = response.history;

        // also set the header since the handout/return status for the reservations might change
        state.reservation_data.header = response.header;
    }
}

// sets notification data
const setStateNotificationDataFromResponse = (state, show, actionAlradyTaken, actionAfterNotificationObj) => {
    state.notification = {
        code: actionAlradyTaken,
        show: show,
        action_after_notification: actionAfterNotificationObj
    }
}

// sets new unavailable article to state's not_available_articles
const setStateAddArticleNotAvailable = (state, articleId, subarticleId = 0) => {
    // Search for the article
    let article = state.not_available_articles.find(({ a_id })=> a_id == articleId);
    // If we have the article in the availability array
    if(typeof(article) !== 'undefined') {
        // Delete the article from the list - will add it back later if needed
        state.not_available_articles = state.not_available_articles.filter(({ a_id })=> a_id != articleId);

        // If we have changed a subarticle and we have subarticles in the availability array for this article
        if(subarticleId && typeof(article?.subarticles) !== 'undefined') {
            // Delete the subarticle from the list - will add it back later if needed
            let filteredSubarticles = article.subarticles.filter(({ id })=> id != subarticleId);

            // Add unavailable subarticle to the list of unavailable subarticles
            filteredSubarticles = [...filteredSubarticles, {
                "id": subarticleId
            }];

            // Push back the article with the subarticles
            article.subarticles = filteredSubarticles;
            state.not_available_articles = [...state.not_available_articles, article];
        } else { // If there are no subarticles, add article to the array
            state.not_available_articles = [...state.not_available_articles, article];
        }

    } else { // No article with this ID was found unavailble
        // If the article/subarticle is NOT available add it to the list of unavailable articles/subarticles
        let article = {
            "a_id": articleId
        };

        // If it's a subarticle, add the unavailable flag to the subarticles array
        if(subarticleId) {
            article.subarticles = [{
                "id": subarticleId
            }];
        }

        state.not_available_articles = [...state.not_available_articles, article];
    }
}

// sets new unavailable article to state's not_available_articles
const setStateRemoveArticleNotAvailable = (state, articleId, subarticleId = 0) => {
    // Search for the article
    let article = state.not_available_articles.find(({ a_id })=> a_id == articleId);
    // If we have the article in the availability array
    if(typeof(article) !== 'undefined') {
        // Delete the article from the list - will add it back later if needed
        state.not_available_articles = state.not_available_articles.filter(({ a_id })=> a_id != articleId);

        // If we have changed a subarticle and we have subarticles in the availability array for this article
        if(subarticleId && typeof(article?.subarticles) !== 'undefined') {
            // Delete the subarticle from the list
            let filteredSubarticles = article.subarticles.filter(({ id })=> id != subarticleId);

            // If we have any other subarticles not available push back the article with the remaining unavailable subarticles
            if(filteredSubarticles.length) {
                article.subarticles = filteredSubarticles;
                state.not_available_articles = [...state.not_available_articles, article]
            }
        }
    }
}

// Remove the selected not saved subarticle from the contract - no need for server call as this one is not saved
const setStateRemoveNotSavedSubarticleFromContract = (state, articleId, subarticleId, recurringId = 0, recurringPos = 0) => {
    // Search for the article that contains the subarticle to be removed
    let articleIndex = state.reservation_data.articles.findIndex(({ a_id, recurring_id, recurrence_pos }) => a_id == articleId && recurrence_pos == recurringPos && recurring_id == recurringId);
    // Remove the not saved subarticle
    state.reservation_data.articles[articleIndex].subarticles = state.reservation_data.articles[articleIndex].subarticles.filter(
        ({subarticle_id, recurring_id, recurrence_pos}) => !(subarticle_id == subarticleId && recurrence_pos == recurringPos && recurring_id == recurringId)
    );
}

export const slice = createSlice({
    name: 'reservation',
    initialState,

    reducers: {
        setReservationId: (state, action) => {
            state.reservation_id = action.payload;
        },
        
        hideNotification: (state) => {
            state.notification = {
                code: "",
                show: false,
                action_after_notification: null,
            }
        },
        
        removeArticleNotAvailable: (state, action) => {
            let articleId = action.payload.articleId;
            let subarticleId = action.payload.subarticleId;

            setStateRemoveArticleNotAvailable(state, articleId, subarticleId);
        },
        
        addArticleNotAvailable: (state, action) => {
            let articleId = action.payload.articleId;
            let subarticleId = action.payload.subarticleId;

            setStateAddArticleNotAvailable(state, articleId, subarticleId);
        },
        
        removeNotSavedSubarticleFromContract: (state, action) => {
            let articleId = action.payload.article_id;
            let subarticleId = action.payload.subarticle_id;
            let recurringId = action.payload.recurring_id;
            let recurringPos = action.payload.recurring_pos;

            setStateRemoveNotSavedSubarticleFromContract(state, articleId, subarticleId, recurringId, recurringPos);
        }
    },

    extraReducers: {
        [getReservation.pending]: (state) => {
            state.status = 'loading';
        },
        [getReservation.fulfilled]: (state, action) => {
            state.status = 'succeeded';

            state.reservation_data = rearangeArticlesData(action.payload, state);

            // used in some functions from functions.js
            window.contract_type = state.reservation_data.header.type;
            window.idcontract = state.reservation_data.header.id;
        },
        [getReservation.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },

        [addNewArticle.fulfilled]: (state, action) => {
            const response = action.payload

            if(response.success === true) {
                setStateArticleDataFromResponse(response, state);
            } else {

                // this response comes when a new subarticle could not be added for the requested interval, but there are subarticles available for different intervals
                // the received subarticle will be shown to the user with a marking that is not available, the user can change to different dates, if the subarticle is available it will be saved
                if(response.success === "ok_for_alternative_interval") {

                    // add this to the subarticles collection of the parent article
                    const articleIndex = state.reservation_data.articles.findIndex(a => a.a_id == response.not_saved_subarticle.article_id && a.recurring_id == 0 && a.recurrence_pos == 0);
                    if(articleIndex !== -1) {
                        const article = state.reservation_data.articles[articleIndex];
                        article.subarticles.push(response.not_saved_subarticle);
                    }
                }
            }
        },
        

        [setNewCustomer.fulfilled]: (state, action) => {
            if(action.payload.success) {
                state.reservation_data.customer = action.payload.customer;
                state.reservation_data.history = action.payload.history;
            }            
        },

        // to do: change the api to returen the whole articles, this way the code here would be the same as in removeArticleFromContract.fulfilled
        [setArticleDates.fulfilled]: (state, action) => {
            const response = action.payload;

            setStateArticleDataFromResponse(response, state);
        },

        [setArticleCount.fulfilled]: (state, action) => {
            const response = action.payload;

            setStateArticleDataFromResponse(response, state);
        },

        [removeArticleFromContract.fulfilled]: (state, action) => {
            const response = action.payload;

            setStateArticleDataFromResponse(response, state);
        },

        [replaceSubarticleInContract.fulfilled]: (state, action) => {
            const response = action.payload;

            setStateArticleDataFromResponse(response, state);
        },

        [anonymize.fulfilled]: (state, action) => {
            const response = action.payload;

            if(response.success === true) {
                state.reservation_data.header.anonymous = 1;
                state.reservation_data.customer = action.payload.customer;
                state.reservation_data.history = action.payload.history;
            }
        },

        [convertRequestToReservation.fulfilled]: (state, action) => {
            const response = action.payload;

            if(response.success === true) {
                // ANDY - 12.07.2022 - if we do not receive action_already_taken just redirect to reservation
                if(response.action_already_taken && response.action_already_taken != "") {
                    setStateNotificationDataFromResponse(state, true, response.action_already_taken, {'redirect_to': response.redirect_to});
                } else {
                    window.location.replace(response.redirect_to);
                }
            }
        },

        [changeReservationStatus.fulfilled]: (state, action) => {
            const response = action.payload;

            if(response.success === true) {
                if(response?.contract_available === false) {
                    state.not_available_articles = response.articles;
                } else {
                    state.reservation_data.header = response.header;
                    state.reservation_data.history = response.history;
                }
            }
        },

        [rejectRequest.fulfilled]: (state, action) => {
            const response = action.payload;

            if(response.success === true) {
                // ANDY - 12.07.2022 - if we do not receive action_already_taken just set the new header and history
                if(response.action_already_taken && response.action_already_taken != "") {
                    setStateNotificationDataFromResponse(state, true, response.action_already_taken, {'redirect_to': response.redirect_to});
                } else {
                    state.reservation_data.header = response.header;
                    state.reservation_data.history = response.history;
                }
            }
        },
        
        [addNewHandout.fulfilled]: (state, action) => {
            const response = action.payload;

            setStateArticleDataFromResponse(response, state);
        },

        [changeHandout.fulfilled]: (state, action) => {
            const response = action.payload;

            setStateArticleDataFromResponse(response, state);
        },

        [addNewReturn.fulfilled]: (state, action) => {
            const response = action.payload;

            setStateArticleDataFromResponse(response, state);
        },

        [changeReturn.fulfilled]: (state, action) => {
            const response = action.payload;

            setStateArticleDataFromResponse(response, state);
        },

        [handoutAll.fulfilled]: (state, action) => {
            const response = action.payload;

            setStateArticleDataFromResponse(response, state);
        },

        [returnAll.fulfilled]: (state, action) => {
            const response = action.payload;

            setStateArticleDataFromResponse(response, state);
        }
    }
});

export const { setReservationId, hideNotification, addArticleNotAvailable, removeArticleNotAvailable, removeNotSavedSubarticleFromContract } = slice.actions;

export const selectReservationId = state => state.reservation.reservation_id;
export const selectReservationHeader = state => state.reservation.reservation_data.header;
export const selectReservationCustomer = state => state.reservation.reservation_data.customer;
export const selectReservationArticles = state => state.reservation.reservation_data.articles;
export const selectReservationHistory = state => state.reservation.reservation_data.history;
export const selectReminders = state => state.reservation.reservation_data.reminders;
export const selectCanAnonymize = state => state.reservation.reservation_data.can_anonymize;
export const selectNotification = state => state.reservation.notification;

export const getArticleAvailabilityById = (articleId, subarticleId) => store => {
    let article = store.reservation.not_available_articles.find(({ a_id }) => a_id == articleId);
    // If article not found in not available articles -> available
    if(typeof(article) === 'undefined') {
        return true;
    }

    // If we have the article check if it has subarticles
    if(typeof(article?.subarticles) === 'undefined' || !subarticleId) {
        // NO subarticles -> unavailable
        return false;
    } else {
        let subarticle = article.subarticles.find(({ id }) => id == subarticleId);
        return typeof(subarticle) === 'undefined';
    }
}
export const selectIsContractAvailable = state => state.reservation.not_available_articles.length == 0 ? true : false;

export const selectIsReservation = (state) => {
    const reservationHeader = state.reservation.reservation_data.header;
    const isReservation = reservationHeader ? isTypeReservation(reservationHeader.type) : false;

    return isReservation;
}

export default slice.reducer;