
/* global moment */

import React, { useState } from 'react';
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types';
import classNames from 'classnames';

import InputMask from 'react-input-mask';

import InplaceEditPanel from "./InplaceEditPanel";

import {
    selectInPlaceEditorShowing
} from '../../store/inplaceEditorSlice';

const CountAndDateSection = (props) => {
    
    const isInplaceEditAlreadyShowing = useSelector(selectInPlaceEditorShowing);

    const [currentCount, setCurrentCount] = useState(props.count == 0 ? "" : props.count);
    const [currentDay, setCurrentDay] = useState(props.date);
    const [isEditing, setIsEditing] = useState(false);
    const [isDateValid, setIsDateValid] = useState(true);
    const [isCountValid, setIsCountValid] = useState(true);

    const startEditing = () => { !props.readOnly && !isInplaceEditAlreadyShowing && setIsEditing(true); }
    const stopEditing = () => { setIsEditing(false); }

    // change as typing
    const changeCurrentCount = (event) => {
        let newCount = event.target.value;
        newCount = newCount.replaceAll(",", "");
        newCount = newCount.replaceAll(".", "");

        if(!isNaN(newCount)) {
            setIsCountValid(true);
            setCurrentCount(newCount);
        }
    }

    const onFocusOnDay = (e) => {
        if(currentDay == "" && !e.target.readOnly) {
            setCurrentDay(moment().format("DD.MM.YYYY"));
        }
    }

    const changeCurrentDay = (event) => {
        setIsDateValid(true);
        setCurrentDay(event.target.value);
    }

    const onCancelEditing = () => {
        stopEditing();

        setCurrentCount(props.count);
        setCurrentDay(props.date);

        setIsDateValid(true);
        setIsCountValid(true);
    }

    const checkDateIsValid = (date) => {
        const parsedDate = moment(date, 'DD.MM.YYYY', true);
        return parsedDate.isValid();
    }

    const checkCountIsValid = (count) => {
        const intCount = parseInt(count);

        // zero would remove the handout
        let isNewCountValid = (0 <= intCount && intCount <= props.maxCount);
        if(!isNewCountValid) {
            return false;
        }

        // if a callback is defined, call it
        if(props.canChangeCount) {
            isNewCountValid = props.canChangeCount(intCount);
        }

        return isNewCountValid;
    }

    const onOkEditing = () => {
        const currentCountIsValid = checkCountIsValid(currentCount);
        const currentDayIsValid = checkDateIsValid(currentDay);

        setIsCountValid(currentCountIsValid);
        setIsDateValid(currentDayIsValid);        

        if(currentCountIsValid && currentDayIsValid) {
            stopEditing();

            // call parent with new data
            props.onCountAndDateChanged(currentCount, currentDay)
        }        
    }

    let dateCssClasses = classNames("day", {red: !isDateValid});
    let countCssClasses = classNames({red: !isCountValid});

    // make the inputs read only, if the component is read only, of if another inplace editor is showing (but not for this component)
    let inputsAreReadOnly = props.readOnly || (!isEditing && isInplaceEditAlreadyShowing);

    // the InplaceEditPanel has a z-index of 3
    // so when it becomes active, the other elements, that show on top of the InplaceEditPanel have a z-index of 4
    return (
        <div className={props.className}>
            <InplaceEditPanel isActive={isEditing} onOk={onOkEditing} onCancel={onCancelEditing} />

            <div className={props.childredClassNamePrefix + "_count"} style={isEditing ? {zIndex: "4" } : {}} >
                <input value={currentCount} onChange={changeCurrentCount} className={countCssClasses} onClick={startEditing} readOnly={inputsAreReadOnly} />
            </div>
            <div className={props.childredClassNamePrefix + "_date"} style={isEditing ? {zIndex: "4" } : {}}>
                <InputMask value={currentDay} onChange={changeCurrentDay} className={dateCssClasses} mask='99.99.9999' onClick={startEditing} onFocus={onFocusOnDay} readOnly={inputsAreReadOnly} />
            </div>
        </div>
    );
}

CountAndDateSection.propTypes = {
    maxCount: PropTypes.number.isRequired,
    count: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,

    canChangeCount: PropTypes.func,
    onCountAndDateChanged: PropTypes.func.isRequired,

    className: PropTypes.string.isRequired,
    childredClassNamePrefix: PropTypes.string.isRequired,

    readOnly: PropTypes.bool,
}

export default CountAndDateSection;