import React, { useState } from 'react';
import LinkButton from "../common/components/LinkButton";
import DOMPurify from 'dompurify';

import { useSelector, useDispatch } from 'react-redux';

import lang from "../common/lang/de.json";

import Separator from "../common/components/Separator";
import ActionButton from "../common/components/ActionButton";
import Popup from "../common/components/Popup";

import pdfLink from "./PDFLink";

import { STATUS_RESERVED, STATUS_ENDED, STATUS_CANCELED, isRequestOpen, isReservationActive, isReservationHandedOut, isReservationFullyReturned } from "./ReservationStatus";

import {
    selectReservationId,
    selectReservationHeader,
    selectIsReservation,
    anonymize,
    convertRequestToReservation,
    changeReservationStatus,
    selectCanAnonymize,
    selectReservationArticles,
    rejectRequest,
    selectNotification,
    hideNotification,
    selectIsContractAvailable
} from './store/reservationSlice';

const ReservationHeader = () => {
    const reservationHeader = useSelector(selectReservationHeader);
    const reservationId = useSelector(selectReservationId);
    const isReservation = useSelector(selectIsReservation);
    const canAnonymize = useSelector(selectCanAnonymize);
    const notification = useSelector(selectNotification);
    const isContractAvailable = useSelector(selectIsContractAvailable);

    const articles = useSelector(selectReservationArticles);

    const dispatch = useDispatch();

    const [showRejectRequestConfirmationPopup, setShowRejectRequestConfirmationPopup] = useState(false);
    const [showAnonymizeConfirmationPopup, setShowAnonymizeConfirmationPopup] = useState(false);
    const [showCancelContractConfirmationPopup, setShowCancelContractConfirmationPopup] = useState(false);
    const [showEndContractConfirmationPopup, setShowEndContractConfirmationPopup] = useState(false);

    if(!reservationHeader) {
        return <></>;
    }

    const isAnonymous = reservationHeader.anonymous == 1;
    
    let reservationNumber = reservationHeader.vertragsnr;
    let reservationDate = reservationHeader.vertragsdatum;
    let reservationStatus = reservationHeader.status;
    let reservationHandedOutStatus = reservationHeader.handed_out;
    let reservationReturnedStatus = reservationHeader.all_returned;

    let title = lang.reservation.title;
    let pdfLabel = lang.reservation.pdf_reservation;
    let pdfUrlPrefix = "reservation";

    let titleAnonymize = lang.reservation.title_anonymize;
    let textAnonymize = lang.reservation.confirm_text_anonymize;

    if(!isReservation) {
        title = lang.request.title;
        pdfLabel = lang.request.pdf_reservation;
        pdfUrlPrefix = "request";

        titleAnonymize = lang.request.title_anonymize;
        textAnonymize = lang.request.confirm_text_anonymize;
    }

    const hasArticles = () => {
        return articles && articles.length > 0;
    }

    const onRejectRequestConfirmed = () => {
        dispatch(rejectRequest({ status: STATUS_CANCELED }));
        setShowRejectRequestConfirmationPopup(false);
    }

    const onAnonymizeClicked = () => {
        dispatch(anonymize());
        setShowAnonymizeConfirmationPopup(false);
    }

    const onConvertToReservationClicked = () => {
        dispatch(convertRequestToReservation());
    }

    const doChangeReservationStatus = (status) => {
        dispatch(changeReservationStatus({status}));
    }

    const onCancelContractConfirmed = () => {
        doChangeReservationStatus(STATUS_CANCELED);
        setShowCancelContractConfirmationPopup(false);
    }

    const onEndContractConfirmed = () => {
        doChangeReservationStatus(STATUS_ENDED);
        setShowEndContractConfirmationPopup(false);
    }

    const onNotificationConfirmed = () => {
        // If we have a action after notification (right now we only have redirect)
        if (notification.action_after_notification && notification.action_after_notification.redirect_to) {
            window.location.replace(notification.action_after_notification.redirect_to);
        }
        dispatch(hideNotification());
    }

    const idForActivateButtonDisabledTooltip = "activate_button_disabled_tooltip";

    return (
        <>
            <div className="header">
                <h2 className="text-uppercase alpha secondary-ff">{title}</h2>
                <div className="contract_buttons">
                    {!isAnonymous && canAnonymize &&
                        <ActionButton onClick={() => setShowAnonymizeConfirmationPopup(true)} text={lang.anonymize} />
                    }

                    {isReservation ?
                        <>
                            {isReservationActive(reservationStatus) ?
                                <>
                                    {!isReservationHandedOut(reservationHandedOutStatus) &&
                                        <ActionButton onClick={() => setShowCancelContractConfirmationPopup(true)} text={lang.reservation.cancel_contract} className="red" />
                                    }

                                    {isReservationFullyReturned(reservationReturnedStatus) &&
                                        <ActionButton onClick={() => setShowEndContractConfirmationPopup(true)} text={lang.reservation.end_contract} className="blue" />
                                    }
                                </>
                                :
                                <ActionButton tooltip_show={!isContractAvailable} tooltip_text={lang.request.cannot_activate_text} tooltip_id={idForActivateButtonDisabledTooltip} onClick={() => doChangeReservationStatus(STATUS_RESERVED)} text={lang.reservation.activate_contract} disabled={!isContractAvailable} />
                            }
                        </>
                        :
                        <>
                            {hasArticles() && !isAnonymous && isRequestOpen(reservationStatus) &&
                                <ActionButton onClick={onConvertToReservationClicked} text={lang.request.convert_to_reservation} />
                            }

                            {isRequestOpen(reservationStatus) &&
                                <ActionButton onClick={() => setShowRejectRequestConfirmationPopup(true)} text={lang.request.reject_request} className="rot" />
                            }
                        </>
                    }
                </div>

                <div className="right_block">
                    <LinkButton textLeft="PDF" textRight={pdfLabel} link={pdfLink(pdfUrlPrefix, reservationId)} />
                    {isReservation &&
                        <>
                            <LinkButton textLeft="PDF" textRight={lang.reservation.pdf_delivery_note} link={pdfLink("delivery", reservationId)} className="margin" />
                            <LinkButton textLeft="PDF" textRight={lang.reservation.pdf_package_list} link={pdfLink("package", reservationId)} className="margin" />
                        </>
                    }
                </div>

            </div>

            <div className="meta">
                {lang.reservation.number} {reservationNumber} {lang.reservation.from} {reservationDate}
                
                <Separator />
                <span>{lang.reservation.status}: {reservationHeader.status_text}</span>
                
                {reservationHeader.usage_text != "" &&
                    <>
                        <Separator />
                        <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(reservationHeader.usage_text) }} />
                    </>
                }
            </div>

            {showRejectRequestConfirmationPopup &&
                <Popup title={lang.request.reject_request_title} okButtonLabel={lang.reservation.reject_request} okButtonClass="red"
                    onOkClicked={onRejectRequestConfirmed} onCancelClicked={() => setShowRejectRequestConfirmationPopup(false)}>

                    <span>{lang.request.confirm_reject_request}</span>
                    <br />
                </Popup>
            }

            {showAnonymizeConfirmationPopup &&
                <Popup title={titleAnonymize} okButtonLabel={lang.anonymize} okButtonClass="red"
                    onOkClicked={onAnonymizeClicked} onCancelClicked={() => setShowAnonymizeConfirmationPopup(false)}>

                    <span>{textAnonymize}</span>
                    <br />
                </Popup>
            }

            {showCancelContractConfirmationPopup &&
                <Popup title={lang.reservation.cancel_reservation} okButtonLabel={lang.reservation.cancel_contract} okButtonClass="red"
                    onOkClicked={onCancelContractConfirmed} onCancelClicked={() => setShowCancelContractConfirmationPopup(false)}>

                    <span>{lang.reservation.confirm_cancel_reservation}</span>
                    <br />
                </Popup>
            }

            {showEndContractConfirmationPopup &&
                <Popup title={lang.reservation.end_reservation} okButtonLabel={lang.reservation.end_contract} okButtonClass="blue"
                    onOkClicked={onEndContractConfirmed} onCancelClicked={() => setShowEndContractConfirmationPopup(false)}>

                    <span>{lang.reservation.confirm_end_reservation}</span>
                    <br />
                </Popup>
            }

            {notification && notification.show && 
                <Popup title={lang.reservation.action_already_taken} okButtonClass="blue"
                    onOkClicked={onNotificationConfirmed} showCancelButton={false} onCancelClicked={() => {}}>

                    <span>{lang.reservation[notification.code]}</span>
                    <br />
                </Popup>
            }
        </>
    )
}

export default ReservationHeader;