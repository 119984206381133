/* global moment */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import classNames from "classnames";
import DOMPurify from 'dompurify';

import FromToInterval from "./FromToInterval";
import CountAndHandedOutSection from "./CountAndHandedOutSection";
import ReturnsSection from "./ReturnsSection";
import DeleteArticleButton from './DeleteArticleButton';
import ReplaceSubarticleButton from './ReplaceSubarticleButton';

import ArticleAvailabilityApi from "../../api/ArticleAvailabilityApi";

import { getHandedoutCount, getReturnedCount } from "./HandoutsReturnsUtils";
import { getSubarticleNumber } from "../../../common/utils";

import lang from "../../../common/lang/de.json";

import { STATUS_RESERVED, STATUS_HANDEDOUT, STATUS_RETURNED } from "../ArticleStatus";

import {
    selectReservationId,
    selectIsReservation,
    setArticleDates,
    setArticleCount,
    convertDateForServer,
    addArticleNotAvailable,
    removeArticleNotAvailable,
    getArticleAvailabilityById
} from '../../store/reservationSlice';

const getInfoForUI = (article, subarticle, isFirst) => {
    let shouldDisplayImgAndName = true,
        imgUrl = article.img_url,
        articleId = article.a_id,
        subarticleId = 0,
        articleName = article.a_name,
        number = article.gnr,
        status = article.in_contract_status,
        ui_status = lang.reservation.article_status[article.in_contract_status],
        from = article.a_from,
        to = article.a_to,
        count = article.a_menge,
        recurringId = article.recurring_id,
        recurrencePos = article.recurrence_pos,
        handouts = article.handouts,
        returns = article.returns,
        articleStateText = false,
        incoming = article.incoming == "1" ? true : false,
        overdue = article.overdue == "1" ? true : false;

    if(article.del == 1) {
        articleStateText = lang.article_state.deleted;
    } else if(article.available == 0) {
        articleStateText = lang.article_state.inactive;
    }

    if(subarticle) {
        // only the first sub displays the article name and image
        shouldDisplayImgAndName = isFirst;
        subarticleId = subarticle.subarticle_id;
        number = getSubarticleNumber(number, subarticle.number);
        status = subarticle.in_contract_status,
        ui_status = lang.reservation.article_status[subarticle.in_contract_status],
        from = subarticle.start_date;
        to = subarticle.end_date;
        count = 1;
        recurringId = subarticle.recurring_id;
        recurrencePos = subarticle.recurrence_pos;
        handouts = subarticle.handouts;
        returns = subarticle.returns;
        incoming = subarticle.incoming == "1" ? true : false;
        overdue = subarticle.overdue == "1" ? true : false;

        if(subarticle.del == 1) {
            articleStateText = lang.article_state.deleted;
        } else if(subarticle.is_active == 0) {
            articleStateText = lang.article_state.inactive;
        }
    }

    return {shouldDisplayImgAndName, imgUrl, articleId, subarticleId, articleName, number, status, ui_status, from, to, count, recurringId, recurrencePos, handouts, returns, incoming, overdue, articleStateText};
}

const ArticlesTableEntry = (props) => {
    const dispatch = useDispatch();

    const [ lastUsedFromInAvailabilityCheck, setLastUsedFromInAvailabilityCheck ] = useState('');
    const [ lastUsedToInAvailabilityCheck, setLastUsedToInAvailabilityCheck ] = useState('');

    const { article, subarticle, first } = props;
    const isSubarticle = !!subarticle;

    const reservationId = useSelector(selectReservationId);
    const isReservation = useSelector(selectIsReservation);

    const { shouldDisplayImgAndName, imgUrl, articleId, subarticleId, articleName, number, status, ui_status, from, to, count, recurringId, recurrencePos, handouts, returns, articleStateText, incoming, overdue } = getInfoForUI(article, subarticle, first);

    const isAvailable = useSelector(getArticleAvailabilityById(articleId, subarticleId));

    const checkSubarticleAvailability = async (from, to) => {
        setLastUsedFromInAvailabilityCheck(from);
        setLastUsedToInAvailabilityCheck(to);

        const response = await ArticleAvailabilityApi.checkSubarticle(reservationId, from, to, subarticle.subarticle_id, article.days_block_before, article.days_block_after);
        return response.success && response.is_subarticle_available;
    }

    const checkAvailability = async (from, to, count) => {
        setLastUsedFromInAvailabilityCheck(from);
        setLastUsedToInAvailabilityCheck(to);

        const response = await ArticleAvailabilityApi.check(reservationId, from, to, article.a_id, count, article.days_block_before, article.days_block_after);
        return response.success && response.is_available;
    }

    const onTimeIntervalChanged = async (from, to) => {
        let isArticleAvailable = true;

        if(isSubarticle) {
            isArticleAvailable = await checkSubarticleAvailability(from, to);
        } else {
            isArticleAvailable = await checkAvailability(from, to, article.a_menge);
        }

        if(isArticleAvailable) {
            dispatch(removeArticleNotAvailable({
                articleId,
                subarticleId
            }));

            dispatch(setArticleDates({
                article_id: articleId,
                subarticle_id: subarticleId,
                from: from,
                to: to,
                recurring_id: recurringId,
                recurrence_pos: recurrencePos
            }));
        } else {
            dispatch(addArticleNotAvailable({
                articleId,
                subarticleId
            }));
        }
    }

    const onCountChange = async (count) => {
        const from = convertDateForServer(article.a_from);
        const to = convertDateForServer(article.a_to);

        const isArticleAvailable = await checkAvailability(from, to, count);

        if(isArticleAvailable) {
            dispatch(removeArticleNotAvailable({
                articleId,
                subarticleId
            }));
            dispatch(setArticleCount({
                article_id: article.a_id,
                new_count: count,
                recurring_id: article.recurring_id,
                recurrence_pos: article.recurrence_pos
            }));
        } else {
            dispatch(addArticleNotAvailable({
                articleId,
                subarticleId
            }));
        }

        return isArticleAvailable;
    }

    // on request to add new subarticle to contract, it can happen that no subarticles are available for the requested time interval, but not all subarticles are yet in the contract
    // in this case a subarticle is returned from the server and displayed as not available
    const isNotSavedSubarticle = subarticle && subarticle.not_saved;
    const cssClasses = classNames("article", {not_available: (!isAvailable || isNotSavedSubarticle)});
    const statusCssClasses = classNames("status", {green: status == STATUS_HANDEDOUT || status == STATUS_RETURNED});

    const handedOutCount = getHandedoutCount(handouts);
    const returnedCount = getReturnedCount(returns);

    return (
        <div className={cssClasses}>
            <div className="description">
                {shouldDisplayImgAndName &&
                    <>
                        <img src={imgUrl} />
                        <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(articleName) }} />
                    </>
                }
            </div>
            <div className="number">
                <div className='number_and_stat'>
                    <span>{number}</span>
                    {articleStateText &&
                        <span className='color_red'>{articleStateText}</span>
                    }
                </div>

                {isSubarticle && subarticle.in_contract_status == STATUS_RESERVED &&
                    <ReplaceSubarticleButton reservationId={reservationId} article={props.article} subarticle={props.subarticle}
                        lastUsedFromInAvailabilityCheck={lastUsedFromInAvailabilityCheck}
                        lastUsedToInAvailabilityCheck={lastUsedToInAvailabilityCheck} />
                }
            </div>
            {!incoming && overdue &&
                <div className="status overdue"><span>ÜBERFÄLLIG</span></div>}
            {incoming && !overdue &&
                <div className="status incoming"><span>ANSTEHEND</span></div>}
            {!incoming && !overdue &&
                <div className={statusCssClasses}>{ui_status}</div>}
            

            <FromToInterval article_id={articleId} subarticle_id={subarticleId} recurring_id={recurringId} recurrence_pos={recurrencePos}
                from={from} to={to} onChange={onTimeIntervalChanged} />

            <CountAndHandedOutSection article_id={articleId} subarticle_id={subarticleId} recurring_id={recurringId} recurrence_pos={recurrencePos}
                count={count} isSubarticle={isSubarticle} onDoCountChange={onCountChange} isReservation={isReservation} readOnly={!isAvailable}
                handouts={handouts} returned_count={returnedCount} />

            <ReturnsSection article_id={articleId} subarticle_id={subarticleId} recurring_id={recurringId} recurrence_pos={recurrencePos}
                isReservation={isReservation} readOnly={!isAvailable}
                handouts_count={handedOutCount} returns={returns} />

            <DeleteArticleButton article={props.article} subarticle={props.subarticle} />
        </div>

    );
}

ArticlesTableEntry.propTypes = {
    article: PropTypes.object.isRequired,
    subarticle: PropTypes.object,
    first: PropTypes.bool
}

export default ArticlesTableEntry;
