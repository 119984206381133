/* global moment */

import React from 'react';
import { useSelector } from 'react-redux';

import Separator from "../common/components/Separator";
import lang from "../common/lang/de.json"

import { selectReservationHistory } from "./store/reservationSlice";

const ReservationHistory = () => {
    const history = useSelector(selectReservationHistory);
    if(!history) {
        return <></>;
    }
    
    const renderHistoryEntry = (entry) => {
        const entryTime = moment(entry.when);

        return (
            <div key={entry.id} className="history_entry">{entryTime.format("DD.MM.YYYY HH:mm")} {entry.action}
                {entry.full_name != "" &&
                    <>
                    <Separator />{entry.full_name}
                    </>
                }
            </div>
        );
    }

    return (
        <div className="dash-block-container history">
            <div className="dash-block-header text-uppercase">{lang.reservation.history_title}</div>

            <div className="dash-block-content flex flex__column history_entries">
                {history.map((historyEntry) => 
                    renderHistoryEntry(historyEntry))
                }
            </div>
        </div>
    );
}

export default ReservationHistory;