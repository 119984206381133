// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper

// this is a simple json client use a library for more complex operations

function encodeFormBody(data) {
    var formBody = [];
    for (var property in data) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(data[property]);

        formBody.push(encodedKey + "=" + encodedValue);
    }

    formBody = formBody.join("&");

    return formBody;
}

export async function client(endpoint, { body, ...customConfig } = {}) {
    let headers;

    const doFormDataPost = !!customConfig.formDataPost;
    delete customConfig.formDataPost;

    if (doFormDataPost) {
        headers = { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' };
    } else {
        headers = { 'Content-Type': 'application/json' };
    }

    const config = {
        method: body ? 'POST' : 'GET',
        ...customConfig,
        headers: {
            ...headers,
            ...customConfig.headers,
        },
    }

    if (body) {
        if (!doFormDataPost) {
            config.body = JSON.stringify(body)
        } else {
            config.body = encodeFormBody(body);
        }
    }

    let data
    try {
        const response = await window.fetch(endpoint, config)
        data = await response.json()
        if (response.ok) {
            return data
        }
        throw new Error(response.statusText)
    } catch (err) {
        return Promise.reject(err.message ? err.message : data)
    }
}

client.get = function (endpoint, customConfig = {}) {
    return client(endpoint, { ...customConfig, method: 'GET' })
}

client.post = function (endpoint, body, customConfig = {}) {
    return client(endpoint, { ...customConfig, body })
}
