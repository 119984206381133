import React from 'react';
import { useSelector } from 'react-redux';

import lang from "../../common/lang/de.json";

import { selectIsReservation } from "../store/reservationSlice";

const ArticlesTableHeader = () => {
    const isReservation = useSelector(selectIsReservation);

    // same class names are used in ArticlesTableContent
    return (
        <div className="article qv_table_header">
            <div className="description">{lang.reservation.description}</div>
            <div className="number">{lang.reservation.article_number}</div>
            <div className="status">{lang.reservation.status}</div>

            <div className="from">{lang.reservation.article_from}</div>
            <div className="to">{lang.reservation.article_to}</div>

            <div className="count_handout_section">
                <div className="count">{lang.reservation.count}</div>

                <div className="handout_section">
                {isReservation && 
                    <>
                        <div className="out_count">{lang.reservation.out_count}</div>
                        <div className="out_date">{lang.reservation.out_date}</div>
                    </>
                }
                </div>
                
            </div>

            <div className="return_section">
                {isReservation && 
                <>
                    <div className="return_count">{lang.reservation.return_count}</div>
                    <div className="return_date">{lang.reservation.return_date}</div>
                </>
                }
            </div>

            <div className="delete_article">
            </div>
        </div>
    );
}

export default ArticlesTableHeader;
