
import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import SvgButton from '../../../common/components/SvgButton';
import lang from "../../../common/lang/de.json"

import {
    addNewArticle,
    convertDateForServer
} from '../../store/reservationSlice';

const AddSubarticleButton = (props) => {
    const { article, more_subarticles_available } = props;

    const dispatch = useDispatch();

    const onAddSubarticle = () => {
        // get from to dates from the last subarticle added in contract, this button can be used only if subarticles of an article are already in the contract
        if(article.subarticles.length > 0)
        {
            const lastSubarticle = article.subarticles[article.subarticles.length - 1];

            const from = convertDateForServer(lastSubarticle.start_date);
            const to = convertDateForServer(lastSubarticle.end_date);

            const subarticles_already_in_contract = article.subarticles.map(sa => sa.subarticle_id)

            dispatch(addNewArticle({article_id: article.a_id, from, to, subarticles_already_in_contract}));
        }
    }

    return (
        <div className='article'>
            <div className="description"></div>
            
            <div className="add_subarticles">
                {more_subarticles_available
                    ? <SvgButton svgRef="icon-add" textRight={lang.reservation.add_subarticle} onClick={onAddSubarticle} />
                    : <span>{lang.reservation.subarticles_not_available}</span>
                }
            </div>

        </div>

    );
}

AddSubarticleButton.propTypes = {
    article: PropTypes.object.isRequired,
    more_subarticles_available: PropTypes.bool.isRequired
}

export default AddSubarticleButton;
