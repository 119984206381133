import React, { useState } from 'react';
import { useDispatch  } from 'react-redux';
import PropTypes from 'prop-types';

import SvgButton from '../../../common/components/SvgButton';
import ActionButton from '../../../common/components/ActionButton';
import Popup from '../../../common/components/Popup';

import ReplaceSvg from "../../../../../css/images/replace.svg";

import lang from "../../../common/lang/de.json";

import ReservationApi from "../../api/ReservationApi";

import { getSubarticleNumber } from "../../../common/utils";

import {
    convertDateForServer,
    replaceSubarticleInContract,
    removeArticleNotAvailable
} from '../../store/reservationSlice';

const ReplaceSubarticleButton = (props) => {
    const dispatch = useDispatch();

    const [showPopup, setShowPopup] = useState(false);
    const [availableAlternativeSubarticles, setAvailableAlternativeSubarticles] = useState([]);

    const { reservationId, article, subarticle, lastUsedFromInAvailabilityCheck, lastUsedToInAvailabilityCheck } = props;

    const prepareAlternativeSubarticles = (responseAlternativeSubarticle) => {
        const alternativeSubarticles = [];

        responseAlternativeSubarticle.map((alternativeSubarticle) => {
            const keysOfAlternativeSubarticle = Object.keys(alternativeSubarticle);

            // each elemnt of the returned subarticles has the format {subarticleId: subarticleNumber}
            if(keysOfAlternativeSubarticle.length == 1) {
                const subarticleId = keysOfAlternativeSubarticle[0];
                const subarticleNumber = alternativeSubarticle[subarticleId];

                alternativeSubarticles.push({
                    subarticleId,
                    subarticleNumber,
                    numberForSort: subarticleNumber.padStart(5, "0")
                })
            }
        })

        setAvailableAlternativeSubarticles(alternativeSubarticles.sort((a, b) => a.numberForSort == b.numberForSort ? 0 : a.numberForSort > b.numberForSort ? 1 : -1))
    }

    const getRequestDates = () => {
        const requestFromDate = lastUsedFromInAvailabilityCheck != '' ? lastUsedFromInAvailabilityCheck : convertDateForServer(subarticle.start_date);
        const requestToDate = lastUsedToInAvailabilityCheck != '' ? lastUsedToInAvailabilityCheck : convertDateForServer(subarticle.end_date);

        return {requestFromDate, requestToDate};
    }

    const getAlternativeSubarticles = async () => {

        const subarticles_already_in_contract = article.subarticles.map(sa => sa.subarticle_id)

        const {requestFromDate, requestToDate} = getRequestDates();
        
        const response = await ReservationApi.getAlternativeSubarticles(reservationId, article.a_id, subarticle.subarticle_id, 
            requestFromDate, requestToDate, subarticles_already_in_contract);

        if(response["alternative_subarticles"]) {
            prepareAlternativeSubarticles(response["alternative_subarticles"]);
        }
    }

    const onShopAlternativeSubarticles = () => {
        setShowPopup(true);

        getAlternativeSubarticles();
    }

    const onReplaceSubarticleConfirmed = (newSubarticleId) => {
        setShowPopup(false);

        let subarticleId = subarticle.subarticle_id;
        let recurringId = subarticle.recurring_id;
        let recurrencePos = subarticle.recurrence_pos;

        // ANDY - 14.07.2022 - remove the article from unavavilable articles array when replaced(mark as available)
        dispatch(removeArticleNotAvailable({
            "articleId": article.a_id,
            "subarticleId": subarticleId
        }));

        const {requestFromDate, requestToDate} = getRequestDates();

        dispatch(replaceSubarticleInContract({
            article_id: article.a_id,
            remove_subarticle_id: subarticleId,
            recurring_id: recurringId,
            recurring_pos: recurrencePos,
            new_subarticle_id: newSubarticleId,
            from: requestFromDate,
            to: requestToDate
        }));
    }

    const renderAlternativeSubarticle = (alternativeSubarticle, listIndex) => {
        const {subarticleId, subarticleNumber} = alternativeSubarticle;
        
        return (
            <div key={subarticleId} className="alternative_article_number">
                {getSubarticleNumber(article.gnr, subarticleNumber)}

                <ActionButton onClick={() => onReplaceSubarticleConfirmed(subarticleId)} text={lang.select} />
            </div>
        );
    }

    return (
        <>
            <div className='replace_button'>
                <SvgButton svgFile={ReplaceSvg} onClick={onShopAlternativeSubarticles} />
            </div>

            {showPopup &&
                <Popup title={lang.reservation.replace_subarticle_title} showOkButton={false} className="alternative_subarticles_popup"
                    onOkClicked={onReplaceSubarticleConfirmed} onCancelClicked={() => setShowPopup(false)}>

                <div className="alternative_subarticles">
                    {availableAlternativeSubarticles.map((alternativeSubarticle, i) =>
                        renderAlternativeSubarticle(alternativeSubarticle, i))
                    }

                    {availableAlternativeSubarticles.length === 0 &&
                        <div className="alternative_article_number">
                            {lang.reservation.no_subarticles_available}
                        </div>
                    }
                </div>

                </Popup>
            }
        </>
    );
}

ReplaceSubarticleButton.propTypes = {
    reservationId: PropTypes.number.isRequired,
    article: PropTypes.object.isRequired,
    subarticle: PropTypes.object,
    lastUsedFromInAvailabilityCheck: PropTypes.string.isRequired,
    lastUsedToInAvailabilityCheck: PropTypes.string.isRequired,
}

export default ReplaceSubarticleButton;